var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("GeneralSubHeader", {
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "mr-3",
                    staticStyle: { "font-size": "1.6rem" },
                  },
                  [
                    _c("SVGIcon", {
                      staticClass: "mr-2",
                      attrs: { name: "google", size: "1.6rem" },
                    }),
                  ],
                  1
                ),
                _vm._v(" Google Bulk Update Users "),
                _c("small", { staticClass: "ml-3" }, [
                  _vm._v("Account Creator"),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                false
                  ? _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
                      _c("div", { staticClass: "paginator-container" }, [
                        _c(
                          "div",
                          { staticClass: "kt-pagination kt-pagination--brand" },
                          [
                            _c(
                              "a",
                              {
                                class:
                                  _vm.filterBy == "teachers"
                                    ? "btn btn-primary"
                                    : "btn kt-subheader__btn-secondary",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.filterBy = "teachers"
                                  },
                                },
                              },
                              [_vm._v(" Teachers ")]
                            ),
                            _c(
                              "a",
                              {
                                class:
                                  _vm.filterBy == "students"
                                    ? "btn btn-primary"
                                    : "btn kt-subheader__btn-secondary",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.filterBy = "students"
                                  },
                                },
                              },
                              [_vm._v(" Students ")]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          !_vm.loading
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _vm._m(0),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "kt-pagination kt-pagination--brand pt-3",
                          },
                          [
                            _c("ul", { staticClass: "kt-pagination__links" }, [
                              _c(
                                "li",
                                { staticClass: "kt-pagination__link--prev" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.decrementPreviewIndex.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-left kt-font-brand",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "li",
                                { staticClass: "kt-pagination__link--next" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.incrementPreviewIndex.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-right kt-font-brand",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-portlet__body pb-0" }, [
                      _vm.student
                        ? _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col-5" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12 pt-3" }, [
                                  _vm.student
                                    ? _c(
                                        "div",
                                        {
                                          class: `kt-widget kt-widget--user-profile-2 kt-widget--narrow w-100 mx-4`,
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-widget__head mt-0 pt-0",
                                            },
                                            [
                                              _c("StudentPortfolioAvatar", {
                                                attrs: { student: _vm.student },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget__info w-100",
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "kt-widget__username d-block w-100",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            $event.preventDefault()
                                                            return _vm.$_panelMixins_openPanelForStudent(
                                                              _vm.student
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.student
                                                                .lastName
                                                            ) +
                                                            ", " +
                                                            _vm._s(
                                                              _vm.student
                                                                .firstName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__desc",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$_utilMixins_format_grade_level(
                                                                _vm.student
                                                                  .gradeLevel
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "pull-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.student
                                                                    .homeRoom
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "kt-widget__desc",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.student.dob
                                                            ) +
                                                            " "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "pull-right ml-5",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.student
                                                                    .extStudentId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-6 offset-1" }, [
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Email Alias")]),
                                _c("div", { staticClass: "input-group" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.preview.email,
                                        expression: "preview.email",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", readonly: "" },
                                    domProps: { value: _vm.preview.email },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.preview,
                                          "email",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("div", { staticClass: "form-group" }, [
                                _c("label", [_vm._v("Password")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.preview.password,
                                      expression: "preview.password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { type: "text", readonly: "" },
                                  domProps: { value: _vm.preview.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.preview,
                                        "password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "kt-portlet__foot pb-2" }, [
                      _c("div", { staticClass: "form-group row" }, [
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-right" },
                          [
                            _c(
                              "b-dropdown",
                              {
                                attrs: { variant: "none", "no-caret": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "button-content",
                                      fn: function () {
                                        return [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-bold btn-sm btn-label-brand mt-3 pull-right",
                                            },
                                            [_vm._v(" Create CSV Files ")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3737956982
                                ),
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      variant: "none",
                                      "button-class": "dropdown-item",
                                      "link-class": "kt-link",
                                    },
                                    on: { click: _vm.showGoogleModal },
                                  },
                                  [_vm._v(" Google Bulk Update CSV ")]
                                ),
                                _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      variant: "none",
                                      "button-class": "dropdown-item",
                                      "link-class": "kt-link",
                                    },
                                    on: { click: _vm.exportLinkingCsv },
                                  },
                                  [_vm._v(" SyncGrades Student Linking CSV ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      {
                        staticClass: "kt-portlet__body",
                        on: {
                          click: function ($event) {
                            _vm.dirty = true
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _vm._m(3),
                              _c(
                                "draggable",
                                {
                                  staticClass: "form-group",
                                  attrs: { list: _vm.emailParts },
                                  on: {
                                    start: function ($event) {
                                      _vm.dragging = true
                                    },
                                    end: function ($event) {
                                      _vm.dragging = false
                                    },
                                  },
                                },
                                _vm._l(_vm.emailParts, function (part, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `col_${part.name}_${idx}`,
                                      staticClass: "row",
                                      staticStyle: { "min-height": "64px" },
                                    },
                                    [
                                      _c("div", { staticClass: "col-8" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-input-icon kt-input-icon--left",
                                          },
                                          [
                                            part.align
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: part.name,
                                                      expression: "part.name",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    readonly: "",
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value: part.name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        part,
                                                        "name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            part.hasOwnProperty("value")
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: part.value,
                                                      expression: "part.value",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Add text string to append",
                                                  },
                                                  domProps: {
                                                    value: part.value,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        part,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-input-icon__icon kt-input-icon__icon--left",
                                              },
                                              [
                                                _c("span", [
                                                  _c("i", {
                                                    staticClass:
                                                      "la la-navicon",
                                                  }),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "col-4" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-input-icon kt-input-icon--right",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: part.characters,
                                                  expression: "part.characters",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  part.hasOwnProperty("value"),
                                              },
                                              domProps: {
                                                value: part.characters,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    part,
                                                    "characters",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-input-icon__icon kt-input-icon__icon--right",
                                              },
                                              [
                                                _c(
                                                  "b-dropdown",
                                                  {
                                                    attrs: {
                                                      block: false,
                                                      variant: "none",
                                                      dropup: "",
                                                      "no-caret": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "button-content",
                                                          fn: function () {
                                                            return [
                                                              _c("span", [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "la la-ellipsis-h",
                                                                }),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    part.hasOwnProperty("align")
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            attrs: {
                                                              variant: "none",
                                                              "button-class":
                                                                "dropdown-item",
                                                              "link-class":
                                                                "kt-link",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                part.align =
                                                                  "left"
                                                              },
                                                            },
                                                          },
                                                          [
                                                            part.align == "left"
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "la la-check",
                                                                })
                                                              : _vm._e(),
                                                            _vm._v(
                                                              " Left-most Char "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    part.hasOwnProperty("align")
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            attrs: {
                                                              variant: "none",
                                                              "button-class":
                                                                "dropdown-item",
                                                              "link-class":
                                                                "kt-link",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                part.align =
                                                                  "right"
                                                              },
                                                            },
                                                          },
                                                          [
                                                            part.align ==
                                                            "right"
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "la la-check",
                                                                })
                                                              : _vm._e(),
                                                            _vm._v(
                                                              " Right-most Char "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "b-dropdown-item",
                                                      {
                                                        attrs: {
                                                          variant: "none",
                                                          "button-class":
                                                            "dropdown-item",
                                                          "link-class":
                                                            "kt-link kt-font-danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteEmailPartByIndex(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Delete Item ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        part.align
                                          ? _c(
                                              "div",
                                              { staticClass: "text-muted" },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(part.characters) +
                                                      " " +
                                                      _vm._s(part.align) +
                                                      "-most chars"
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "pull-right",
                                        attrs: {
                                          block: false,
                                          variant: "none",
                                          dropup: "",
                                          "no-caret": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary btn-sm btn-upper mt-3 pull-right",
                                                    },
                                                    [_vm._v(" Add Segment ")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          4243025966
                                        ),
                                      },
                                      _vm._l(
                                        _vm.emailSegments,
                                        function (segment, idx) {
                                          return _c(
                                            "b-dropdown-item",
                                            {
                                              key: `email_segment_${segment.name}_${idx}`,
                                              attrs: {
                                                variant: "none",
                                                "button-class": "dropdown-item",
                                                "link-class": "kt-link",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addEmailPart(
                                                    segment
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(segment.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _vm._m(4),
                              _c(
                                "draggable",
                                {
                                  staticClass: "form-group",
                                  attrs: { list: _vm.passwordParts },
                                  on: {
                                    start: function ($event) {
                                      _vm.dragging = true
                                    },
                                    end: function ($event) {
                                      _vm.dragging = false
                                    },
                                  },
                                },
                                _vm._l(_vm.passwordParts, function (part, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: `col_${part.name}_${idx}`,
                                      staticClass: "row",
                                      staticStyle: { "min-height": "64px" },
                                    },
                                    [
                                      _c("div", { staticClass: "col-8" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-input-icon kt-input-icon--left",
                                          },
                                          [
                                            part.align
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: part.name,
                                                      expression: "part.name",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    readonly: "",
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value: part.name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        part,
                                                        "name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            part.hasOwnProperty("value")
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: part.value,
                                                      expression: "part.value",
                                                    },
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Add text string to append",
                                                  },
                                                  domProps: {
                                                    value: part.value,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        part,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-input-icon__icon kt-input-icon__icon--left",
                                              },
                                              [
                                                _c("span", [
                                                  _c("i", {
                                                    staticClass:
                                                      "la la-navicon",
                                                  }),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "col-4" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-input-icon kt-input-icon--right",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: part.characters,
                                                  expression: "part.characters",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                disabled:
                                                  part.hasOwnProperty("value"),
                                              },
                                              domProps: {
                                                value: part.characters,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    part,
                                                    "characters",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "kt-input-icon__icon kt-input-icon__icon--right",
                                              },
                                              [
                                                _c(
                                                  "b-dropdown",
                                                  {
                                                    attrs: {
                                                      block: false,
                                                      variant: "none",
                                                      dropup: "",
                                                      "no-caret": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "button-content",
                                                          fn: function () {
                                                            return [
                                                              _c("span", [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "la la-ellipsis-h",
                                                                }),
                                                              ]),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    part.hasOwnProperty("align")
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            attrs: {
                                                              variant: "none",
                                                              "button-class":
                                                                "dropdown-item",
                                                              "link-class":
                                                                "kt-link",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                part.align =
                                                                  "left"
                                                              },
                                                            },
                                                          },
                                                          [
                                                            part.align == "left"
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "la la-check",
                                                                })
                                                              : _vm._e(),
                                                            _vm._v(
                                                              " Left-most Char "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    part.hasOwnProperty("align")
                                                      ? _c(
                                                          "b-dropdown-item",
                                                          {
                                                            attrs: {
                                                              variant: "none",
                                                              "button-class":
                                                                "dropdown-item",
                                                              "link-class":
                                                                "kt-link",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                part.align =
                                                                  "right"
                                                              },
                                                            },
                                                          },
                                                          [
                                                            part.align ==
                                                            "right"
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "la la-check",
                                                                })
                                                              : _vm._e(),
                                                            _vm._v(
                                                              " Right-most Char "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "b-dropdown-item",
                                                      {
                                                        attrs: {
                                                          variant: "none",
                                                          "button-class":
                                                            "dropdown-item",
                                                          "link-class":
                                                            "kt-link kt-font-danger",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deletePasswordPartByIndex(
                                                              idx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Delete Item ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        part.align
                                          ? _c(
                                              "div",
                                              { staticClass: "text-muted" },
                                              [
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(part.characters) +
                                                      " " +
                                                      _vm._s(part.align) +
                                                      "-most chars"
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }),
                                0
                              ),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "pull-right",
                                        attrs: {
                                          block: false,
                                          variant: "none",
                                          dropup: "",
                                          "no-caret": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-secondary btn-sm btn-upper mt-3 pull-right",
                                                    },
                                                    [_vm._v(" Add Segment ")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          4243025966
                                        ),
                                      },
                                      _vm._l(
                                        _vm.passwordSegments,
                                        function (segment, idx) {
                                          return _c(
                                            "b-dropdown-item",
                                            {
                                              key: `passwordSegment_${segment.name}_${idx}`,
                                              attrs: {
                                                variant: "none",
                                                "button-class": "dropdown-item",
                                                "link-class": "kt-link",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addPasswordPart(
                                                    segment
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(segment.name) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "kt-portlet__foot" }, [
                      _c("div", { staticClass: "text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-bold btn-sm btn-label-brand",
                            on: { click: _vm.saveSettings },
                          },
                          [_vm._v(" Save Builder Settings ")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("b-modal", {
        ref: "export-google-modal",
        attrs: { id: "export-google-modal", variant: "primary", centered: "" },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function ({ close }) {
              return [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v(" Google User Bulk Update CSV "),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: { click: close },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _c("div", { staticClass: "modal-body" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("Organizational Unit")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.orgUnit,
                          expression: "orgUnit",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.orgUnit },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.orgUnit = $event.target.value
                        },
                      },
                    }),
                    _c("span", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        " Choose an organizational unit to place the users in. Make sure the "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://admin.google.com/ac/orgunits",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" organizational unit exists")]
                      ),
                      _vm._v(" in your Google Admin Console. "),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "kt-checkbox-list" }, [
                      _c(
                        "label",
                        { staticClass: "kt-checkbox kt-checkbox--solid" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.changePassword,
                                expression: "changePassword",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.changePassword)
                                ? _vm._i(_vm.changePassword, null) > -1
                                : _vm.changePassword,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.changePassword,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.changePassword = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.changePassword = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.changePassword = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" Change Password "),
                          _c("span"),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        " Require students to choose a new password at google sign in. "
                      ),
                    ]),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row w-100" }, [
                  _c("div", { staticClass: "offset-6 col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-right",
                        attrs: { type: "button" },
                        on: { click: _vm.exportGoogleCsv },
                      },
                      [
                        _c("i", { staticClass: "fa fa-save" }),
                        _vm._v(" Create CSV "),
                      ]
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Preview "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-md-6" }, [
      _c("p", { staticClass: "pt-3" }, [
        _vm._v(" Make sure you have acccess to the "),
        _c(
          "a",
          {
            attrs: {
              href: "https://admin.google.com/ac/users",
              target: "_blank",
            },
          },
          [_vm._v("Google Admin Console")]
        ),
        _vm._v(" in order to bulk update users. "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Builder "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Email Address Segments")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Password Segments")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }