<template>
<div>
    <GeneralSubHeader>
        <template #title>
            <span
                class="mr-3"
                style="font-size: 1.6rem;"
            >
                <SVGIcon
                    name="google"
                    size="1.6rem"
                    class="mr-2"
                />
            </span>

            Google Bulk Update Users
            <small class="ml-3">Account Creator</small>
        </template>
        <template #buttons>
            <div v-if="false" class="kt-subheader__wrapper mt-1">
                <div class="paginator-container">
                    <div class="kt-pagination kt-pagination--brand">
                        <a
                            :class="filterBy == 'teachers' ? 'btn btn-primary' : 'btn kt-subheader__btn-secondary'"
                            @click.stop.prevent="filterBy = 'teachers'"
                        >
                            Teachers
                        </a>
                        <a
                            :class="filterBy == 'students' ? 'btn btn-primary' : 'btn kt-subheader__btn-secondary'"
                            @click.stop.prevent="filterBy = 'students'"
                        >
                            Students
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </GeneralSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div v-if="!loading" class="row">
            <div class="col-lg-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Preview
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-pagination kt-pagination--brand pt-3">
                                <ul class="kt-pagination__links">
                                    <li class="kt-pagination__link--prev">
                                        <a
                                            href="#"
                                            @click.stop.prevent="decrementPreviewIndex"
                                        >
                                            <i class="fa fa-angle-left kt-font-brand" />
                                        </a>
                                    </li>
                                    <li class="kt-pagination__link--next">
                                        <a
                                            href="#"
                                            @click.stop.prevent="incrementPreviewIndex"
                                        >
                                            <i class="fa fa-angle-right kt-font-brand" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body pb-0">
                        <div v-if="student" class="form-group row">
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-12 pt-3">
                                        <!-- Student Preview -->
                                        <div v-if="student" :class="`kt-widget kt-widget--user-profile-2 kt-widget--narrow w-100 mx-4`">
                                            <div class="kt-widget__head mt-0 pt-0">
                                                <StudentPortfolioAvatar
                                                    :student="student"
                                                />
                                                <div class="kt-widget__info w-100">
                                                    <div>
                                                        <a
                                                            href="#"
                                                            class="kt-widget__username d-block w-100"
                                                            @click.stop.prevent="$_panelMixins_openPanelForStudent(student)"
                                                        >
                                                            {{ student.lastName }}, {{ student.firstName }}
                                                        </a>
                                                        <span class="kt-widget__desc">
                                                            {{ $_utilMixins_format_grade_level(student.gradeLevel) }}
                                                            <span class="pull-right">
                                                                {{ student.homeRoom }}
                                                            </span>
                                                        </span>
                                                        <span class="kt-widget__desc">
                                                            {{ student.dob }}
                                                            <span class="pull-right ml-5">
                                                                {{ student.extStudentId }}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 offset-1">
                                <div class="form-group">
                                    <label>Email Alias</label>
                                    <div class="input-group">
                                        <input
                                            v-model="preview.email"
                                            type="text"
                                            class="form-control"
                                            readonly
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input
                                        v-model="preview.password"
                                        type="text"
                                        class="form-control"
                                        readonly
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot pb-2">
                        <div class="form-group row">
                            <div class="col-md-6">
                                <p class="pt-3">
                                    Make sure you have acccess to the <a href="https://admin.google.com/ac/users" target="_blank">Google Admin Console</a> in order to bulk update users.
                                </p>
                            </div>
                            <div class="col-md-6 text-right">
                                <b-dropdown
                                    variant="none"
                                    no-caret
                                >
                                    <template #button-content>
                                        <button

                                            class="btn btn-bold btn-sm btn-label-brand mt-3 pull-right"
                                        >
                                            Create CSV Files
                                        </button>
                                    </template>
                                    <b-dropdown-item
                                        variant="none"
                                        button-class="dropdown-item"
                                        link-class="kt-link"
                                        @click="showGoogleModal"
                                    >
                                        Google Bulk Update CSV
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        variant="none"
                                        button-class="dropdown-item"
                                        link-class="kt-link"
                                        @click="exportLinkingCsv"
                                    >
                                        SyncGrades Student Linking CSV
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <h3 class="kt-portlet__head-title">
                                Builder
                            </h3>
                        </div>
                    </div>
                    <div class="kt-portlet__body" @click="dirty = true">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Email Address Segments</label>
                                </div>
                                <draggable
                                    :list="emailParts"
                                    class="form-group"
                                    @start="dragging = true"
                                    @end="dragging = false"
                                >
                                    <div
                                        v-for="(part, idx) in emailParts"
                                        :key="`col_${part.name}_${idx}`"
                                        class="row"
                                        style="min-height: 64px;"
                                    >
                                        <div class="col-8">
                                            <div class="kt-input-icon kt-input-icon--left">
                                                <input
                                                    v-if="part.align"
                                                    v-model="part.name"
                                                    readonly
                                                    type="text"
                                                    class="form-control"
                                                >
                                                <input
                                                    v-if="part.hasOwnProperty('value')"
                                                    v-model="part.value"
                                                    type="text"
                                                    placeholder="Add text string to append"
                                                    class="form-control"
                                                >
                                                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                    <span>
                                                        <i class="la la-navicon" />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="kt-input-icon kt-input-icon--right">
                                                <input
                                                    v-model="part.characters"
                                                    type="number"
                                                    class="form-control"
                                                    :disabled="part.hasOwnProperty('value')"
                                                >
                                                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                                    <b-dropdown
                                                        :block="false"
                                                        variant="none"
                                                        dropup
                                                        no-caret
                                                    >
                                                        <template #button-content>
                                                            <span>
                                                                <i class="la la-ellipsis-h" />
                                                            </span>
                                                        </template>
                                                        <b-dropdown-item
                                                            v-if="part.hasOwnProperty('align')"
                                                            variant="none"
                                                            button-class="dropdown-item"
                                                            link-class="kt-link"
                                                            @click="part.align = 'left'"
                                                        >
                                                            <i v-if="part.align == 'left'" class="la la-check" />
                                                            Left-most Char
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            v-if="part.hasOwnProperty('align')"
                                                            variant="none"
                                                            button-class="dropdown-item"
                                                            link-class="kt-link"
                                                            @click="part.align = 'right'"
                                                        >
                                                            <i v-if="part.align == 'right'" class="la la-check" />
                                                            Right-most Char
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            variant="none"
                                                            button-class="dropdown-item"
                                                            link-class="kt-link kt-font-danger"
                                                            @click="deleteEmailPartByIndex(idx)"
                                                        >
                                                            Delete Item
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </span>
                                            </div>
                                            <div v-if="part.align" class="text-muted">
                                                <small>{{ part.characters }} {{ part.align }}-most chars</small>
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                                <div class="form-group row">
                                    <div class="col-12">
                                        <b-dropdown
                                            :block="false"
                                            variant="none"
                                            class="pull-right"
                                            dropup
                                            no-caret
                                        >
                                            <template #button-content>
                                                <button class="btn btn-secondary btn-sm btn-upper mt-3 pull-right">
                                                    Add Segment
                                                </button>
                                            </template>
                                            <b-dropdown-item
                                                v-for="(segment, idx) in emailSegments"
                                                :key="`email_segment_${segment.name}_${idx}`"
                                                variant="none"
                                                button-class="dropdown-item"
                                                link-class="kt-link"
                                                @click="addEmailPart(segment)"
                                            >
                                                {{ segment.name }}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Password Segments</label>
                                </div>
                                <draggable
                                    :list="passwordParts"
                                    class="form-group"
                                    @start="dragging = true"
                                    @end="dragging = false"
                                >
                                    <div
                                        v-for="(part, idx) in passwordParts"
                                        :key="`col_${part.name}_${idx}`"
                                        class="row"
                                        style="min-height: 64px;"
                                    >
                                        <div class="col-8">
                                            <div class="kt-input-icon kt-input-icon--left">
                                                <input
                                                    v-if="part.align"
                                                    v-model="part.name"
                                                    readonly
                                                    type="text"
                                                    class="form-control"
                                                >
                                                <input
                                                    v-if="part.hasOwnProperty('value')"
                                                    v-model="part.value"
                                                    type="text"
                                                    placeholder="Add text string to append"
                                                    class="form-control"
                                                >
                                                <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                    <span><i class="la la-navicon" /></span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="kt-input-icon kt-input-icon--right">
                                                <input
                                                    v-model="part.characters"
                                                    type="number"
                                                    class="form-control"
                                                    :disabled="part.hasOwnProperty('value')"
                                                >
                                                <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                                    <b-dropdown
                                                        :block="false"
                                                        variant="none"
                                                        dropup
                                                        no-caret
                                                    >
                                                        <template #button-content>
                                                            <span>
                                                                <i class="la la-ellipsis-h" />
                                                            </span>
                                                        </template>
                                                        <b-dropdown-item
                                                            v-if="part.hasOwnProperty('align')"
                                                            variant="none"
                                                            button-class="dropdown-item"
                                                            link-class="kt-link"
                                                            @click="part.align = 'left'"
                                                        >
                                                            <i v-if="part.align == 'left'" class="la la-check" />
                                                            Left-most Char
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            v-if="part.hasOwnProperty('align')"
                                                            variant="none"
                                                            button-class="dropdown-item"
                                                            link-class="kt-link"
                                                            @click="part.align = 'right'"
                                                        >
                                                            <i v-if="part.align == 'right'" class="la la-check" />
                                                            Right-most Char
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            variant="none"
                                                            button-class="dropdown-item"
                                                            link-class="kt-link kt-font-danger"
                                                            @click="deletePasswordPartByIndex(idx)"
                                                        >
                                                            Delete Item
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </span>
                                            </div>
                                            <div v-if="part.align" class="text-muted">
                                                <small>{{ part.characters }} {{ part.align }}-most chars</small>
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                                <div class="form-group row">
                                    <div class="col-12">
                                        <b-dropdown
                                            :block="false"
                                            variant="none"
                                            class="pull-right"
                                            dropup
                                            no-caret
                                        >
                                            <template #button-content>
                                                <button class="btn btn-secondary btn-sm btn-upper mt-3 pull-right">
                                                    Add Segment
                                                </button>
                                            </template>
                                            <b-dropdown-item
                                                v-for="(segment, idx) in passwordSegments"
                                                :key="`passwordSegment_${segment.name}_${idx}`"
                                                variant="none"
                                                button-class="dropdown-item"
                                                link-class="kt-link"
                                                @click="addPasswordPart(segment)"
                                            >
                                                {{ segment.name }}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot">
                        <div class="text-right">
                            <button class="btn btn-bold btn-sm btn-label-brand" @click="saveSettings">
                                Save Builder Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <b-modal
        id="export-google-modal"
        ref="export-google-modal"
        variant="primary"
        centered
    >
        <template #modal-header="{close}">
            <h4 class="modal-title">
                Google User Bulk Update CSV
            </h4>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <div class="form-group">
                    <label>Organizational Unit</label>
                    <input
                        v-model="orgUnit"
                        type="text"
                        class="form-control"
                    >
                    <span class="form-text text-muted">
                        Choose an organizational unit to place the users in.
                        Make sure the <a href="https://admin.google.com/ac/orgunits" target="_blank">
                            organizational unit exists</a>
                        in your Google Admin Console.
                    </span>
                </div>
                <div class="form-group">
                    <!-- <label></label> -->
                    <div class="kt-checkbox-list">
                        <label class="kt-checkbox kt-checkbox--solid">
                            <input
                                v-model="changePassword"
                                type="checkbox"
                            >
                            Change Password
                            <span />
                        </label>
                    </div>
                    <span class="form-text text-muted">
                        Require students to choose a new password at google sign in.
                    </span>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <div class="row w-100">
                <div class="offset-6 col-6">
                    <button
                        type="button"
                        class="btn btn-primary pull-right"
                        @click="exportGoogleCsv"
                    >
                        <i class="fa fa-save" />
                        Create CSV
                    </button>
                </div>
            </div>
        </template>
    </b-modal>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import SVGIcon from '../components/SVGIcon/SVGIcon.vue';
import GeneralSubHeader from '../components/GeneralSubHeader.vue';
import StudentPortfolioAvatar from '../components/StudentPortfolioAvatar.vue';
import utilMixins from '../store/mixins/utilMixins';
import panelMixins from '../store/mixins/panelMixins';
import draggable from "vuedraggable";
import moment from "moment";
import * as network from '../network';
import crypto from 'crypto';

export default Vue.extend({
    name: 'GoogleAccountCreator',
    components: {
        SVGIcon,
        GeneralSubHeader,
        StudentPortfolioAvatar,
        draggable,
    },
    mixins: [ utilMixins, panelMixins] ,
    data() { return {
        previewIndex: 0,
        dragging: false,
        dirty: false,
        loading: false,
        changePassword: false,
        orgUnit: null,

        emailParts: [],
        passwordParts: [],
    } },
    computed: {
        student() {
            const { filterBy, previewIndex } = this;
            if (filterBy != 'students') {
                return null;
            }
            return this.users[previewIndex];
        },
        teacher() {
            const { filterBy, previewIndex } = this;
            if (filterBy != 'teachers') {
                return null;
            }
            return this.users[previewIndex];
        },
        users() {
            const { filterBy  } = this;
            const { students, teachers } = this.$store.state.database;
            return (filterBy == 'students' ? students : teachers)
                .map(function(user) {
                    if (filterBy != 'students') return user;
                    return {
                        ...user,
                        dayOfBirth: moment(user.dob).format('DD'),
                        monthOfBirth: moment(user.dob).format('MM'),
                        yearOfBirth: moment(user.dob).format('YYYY'),
                    };
                });
        },
        filterBy: {
            get() {
                return this.$route.query.by || 'students';
            },
            set(by) {
                if (!this.dirty || confirm('Are you sure you want to change the filter? You may have unsaved builder settings.')) {
                    this.$router.push({ query: { by } });
                    this.dirty = false;
                }
                // this.$router.push({ query: { by } });
            },
        },
        preview() {
            const { previewIndex, users } = this;

            if (previewIndex >= users.length) {
                return {
                    email: '',
                    password: '',
                };
            } else {
                const { emailParts, passwordParts, googleDomain } = this;

                const user = users[previewIndex];
                return getCredentialsForUser(user, {
                    passwordParts,
                    emailParts,
                    googleDomain,
                });
            }

        },
        emailSegments() {
            return [{
                name: 'Default Email Settings',
                default: true,
            }, {
                name: 'First Name',
                field: 'firstName',
                characters: 4,
                align: 'left',
            }, {
                name: 'Last Name',
                field: 'lastName',
                characters: 1,
                align: 'left',
            }, {
                name: 'DOB - MM',
                field: 'monthOfBirth',
                characters: 2,
                align: 'left',
            }, {
                name: 'DOB - DD',
                field: 'dayOfBirth',
                characters: 2,
                align: 'left',
            }, {
                name: 'DOB - YYYY',
                field: 'yearOfBirth',
                characters: 4,
                align: 'left',
            }, {
                name: 'Student Id',
                field: 'extStudentId',
                characters: 9,
                align: 'left',
            },{
                name: 'Homeroom',
                field: 'homeRoom',
                characters: 3,
                align: 'left',
            }, {
                name: 'Text',
                value: '',
            }];
        },
        passwordSegments() {
            return [{
                name: 'Default Password Settings',
                default: true,
            }, {
                name: 'DOB - MM',
                field: 'monthOfBirth',
                characters: 2,
                align: 'left',
            }, {
                name: 'DOB - DD',
                field: 'dayOfBirth',
                characters: 2,
                align: 'left',
            }, {
                name: 'DOB - YYYY',
                field: 'yearOfBirth',
                characters: 4,
                align: 'left',
            }, {
                name: 'Student Id',
                field: 'extStudentId',
                characters: 9,
                align: 'left',
            }, {
                name: 'Homeroom',
                field: 'homeRoom',
                characters: 3,
                align: 'left',
            }, {
                name: 'Text',
                value: '',
            }];
        },
        googleDomain() {
            if (this.$store.state.user.school.isDemoUser) {
                return '@gedu.demo.syncgrades.com';
            }
            const { unlinkedGoogleEmail } = this.$store.state.user;
            if (unlinkedGoogleEmail && ['School Admin', 'Staff', 'Teacher']) {
                return `@${unlinkedGoogleEmail.split('@')[1]}`;
            }
            return null;
        },
        hasValidSettings() {
            return this.emailParts.length && this.passwordParts.length;
        },
    },
    watch: {
        filterBy() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        showGoogleModal() {
            if (!this.hasValidSettings) return this.showError('You must have at least one email and password segment.');
            this.$refs['export-google-modal'].show();
        },
        populate() {
            const { showError, filterBy } = this;
            let { loading } = this;
            loading = true;
            network.google.getGoogleAccountCreatorSettings({
                url: {
                    schoolId: this.$store.state.user.school.schoolId,
                    schoolTermId: this.$store.state.user.school.schoolTermId,
                },
            }, (err, data) => {
                loading = false;
                if (err) return showError(err);
                const {googleAccountMasks} = data;

                this.applyDefaultEmailSettings();
                this.applyDefaultPasswordSettings();

                if (googleAccountMasks) {
                    const { studentMasks, teacherMasks } = googleAccountMasks;
                    if (studentMasks && filterBy == 'students') {
                        this.emailParts = studentMasks.emailParts;
                        this.passwordParts = studentMasks.passwordParts;
                    }
                    if (teacherMasks && filterBy == 'teachers') {
                        this.emailParts = teacherMasks.emailParts;
                        this.passwordParts = teacherMasks.passwordParts;
                    }
                }
                this.dirty = false;
            });
        },
        exportLinkingCsv() {
            if (!this.hasValidSettings) return this.showError('You must have at least one email and password segment.');

            const columns = [
                { key: 'extStudentId', title: 'StudentID' },
                { key: 'googleEmail', title: 'Google Email Address'},
                { key: 'gradeLevel', title: 'Grade Level'},
                { key: 'homeRoom', title: 'Off Class'},
                { key: 'schoolEmail', title: 'District Email'},
            ];
            const { emailParts, passwordParts, googleDomain, filterBy } = this;
            const { students, teachers } = this.$store.state.database;
            const users = filterBy == 'students' ? students : teachers;
            const rows = users.map((u) => {
                const row = [];
                const credentials = getCredentialsForUser(u, { passwordParts, emailParts, googleDomain });
                const user = {
                    ...u,
                    googleEmail: credentials.email,
                };

                columns.forEach(function(column) {
                    row.push(user[column.key] || '');
                });
                return row;
            });

            let data = [ columns.map((c) => c.title).join(',') ]
            rows.forEach((row) => {
                data.push( row.join(',') );
            });
            const csv = data.join('\n');

            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            const filename = `syncgrades-${filterBy}-linking`;
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        },
        exportGoogleCsv() {
            if (!this.orgUnit) return this.showError('You must provide an organizational unit.');

            const columns = [
                { key: 'firstName', title: 'First Name [Required]' },
                { key: 'lastName', title: 'Last Name [Required]'},
                { key: 'email', title: 'Email Address [Required]'},
                { key: 'password', title: 'Password [Required]'},
                { key: 'hash', title: 'Password Hash Function [UPLOAD ONLY]'},
                { key: 'orgUnit', title: 'Org Unit Path [Required]'},
            ];
            const nextColumnSet = "New Primary Email [UPLOAD ONLY],Recovery Email,Home Secondary Email,Work Secondary Email,Recovery Phone [MUST BE IN THE E.164 FORMAT],Work Phone,Home Phone,Mobile Phone,Work Address,Home Address,Employee ID,Employee Type,Employee Title,Manager Email,Department,Cost Center,Building ID,Floor Name,Floor Section";
            nextColumnSet.split(',').forEach((title) => {
                columns.push({ key: title, title });
            });
            columns.push({ key: 'changePassword', title: 'Change Password at Next Sign-In'});
            columns.push({ key: 'ignore', title: 'New Licenses [UPLOAD ONLY]'});
            columns.push({ key: 'APPE', title: 'Advanced Protection Program enrollment'});

            const { emailParts, passwordParts, googleDomain, filterBy } = this;
            const { students, teachers } = this.$store.state.database;

            const users = filterBy == 'students' ? students : teachers;
            // const { schoolYear, schoolTerm } = this.$store.state.user.school;
            const { orgUnit } = this; //`/${schoolYear} Term ${schoolTerm} Students`;
            const changePassword = this.changePassword ? 'True' : 'False';

            const APPE = 'False';
            const rows = users.map((u) => {
                const row = [];
                const credentials = getCredentialsForUser(u, { passwordParts, emailParts, googleDomain });


                const user = {
                    ...u,
                    email: credentials.email,
                    changePassword,
                    APPE,
                    password: crypto.createHash('md5').update(credentials.password).digest('hex'),
                    hash: 'MD5',
                    orgUnit,
                };

                columns.forEach(function(column) {
                    row.push(user[column.key] || '');
                });
                return row;
            });

            let data = [ columns.map((c) => c.title).join(',') ]
            rows.forEach((row) => {
                data.push( row.join(',') );
            });
            const csv = data.join('\n');

            const anchor = document.createElement('a');
            anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
            anchor.target = '_blank';
            const filename = `google_bulk_update_${filterBy}`;
            anchor.download = `${filename}.csv`;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            this.$refs['export-google-modal'].hide();
        },
        decrementPreviewIndex() {
            this.previewIndex = Math.max(0, this.previewIndex - 1);
        },
        incrementPreviewIndex() {
            const { students, teachers } = this.$store.state.database;
            const { filterBy } = this;
            const users = filterBy == 'students' ? students : teachers;
            this.previewIndex = Math.min(users.length - 1, this.previewIndex + 1);
        },
        saveSettings() {
            const {schoolId, schoolTermId} = this.$store.state.user.school;
            const { emailParts, passwordParts, showError } = this;
            if (!this.hasValidSettings) return showError('You must have at least one email and password segment.');

            const params = {
                url : {
                    schoolId,
                    schoolTermId,
                    accountType: this.filterBy,
                },
                body: {
                    googleAccountMasks: {
                        emailParts,
                        passwordParts,
                    },
                },
            };
            const v = this;
            network.google.saveGoogleAccountCreatorSettings(params, function(err) {
                if (err) return showError(err);
                setTimeout(() => {
                    v.dirty = false;
                }, 500);
            });
        },
        applytoSyncGrades() {
            console.log('applytoSyncGrades');
        },
        applyDefaultEmailSettings() {
            this.emailParts = [{
                name: 'First Name',
                field: 'firstName',
                characters: 4,
                align: 'left',
            }, {
                name: 'Last Name',
                field: 'lastName',
                characters: 1,
                align: 'left',
            }, {
                name: 'Student Id',
                field: 'extStudentId',
                characters: 3,
                align: 'right',
            }];
        },
        applyDefaultPasswordSettings() {
            this.passwordParts = [{
                name: 'DOB - MM',
                field: 'monthOfBirth',
                characters: 2,
                align: 'left',
            }, {
                name: 'DOB - DD',
                field: 'dayOfBirth',
                characters: 2,
                align: 'left',
            }, {
                name: 'DOB - YYYY',
                field: 'yearOfBirth',
                characters: 4,
                align: 'left',
            }];
        },
        addEmailPart(segment) {
            if (segment.default) return this.applyDefaultEmailSettings();

            if (segment.name == 'Text') {
                this.emailParts.push({
                    name: 'Text',
                    value: '',
                });
            } else {
                this.emailParts.push({...segment});
            }
        },
        addPasswordPart(segment) {
            if (segment.default) return this.applyDefaultPasswordSettings();

            if (segment.name == 'Text') {
                this.passwordParts.push({
                    name: 'Text',
                    value: '',
                });
            } else {
                this.passwordParts.push({...segment});
            }
        },
        deleteEmailPartByIndex(index) {
            const emailParts = [...this.emailParts];
            this.emailParts = emailParts.filter((part, idx) => idx != index);
        },
        deletePasswordPartByIndex(index) {
            const passwordParts = [...this.passwordParts];
            this.passwordParts = passwordParts.filter((part, idx) => idx != index);
        },
    },
});

function getCredentialsForUser(u, settings) {

    const { passwordParts, emailParts, googleDomain } = settings;

    const user = {
        ...u,
        dayOfBirth: moment(u.dob).format('DD'),
        monthOfBirth: moment(u.dob).format('MM'),
        yearOfBirth: moment(u.dob).format('YYYY'),
    };

    const output = {
        email: '',
        password: '',
    };

    emailParts.forEach(part => {
        if (part.name == 'Text') {
            output.email += part.value;
        } else {
            const field = part.field;
            const dbValue = user[field];
            if (part.characters > 0 && dbValue) {
                if (part.align == 'left') {
                    output.email += dbValue.substring(0, part.characters);
                } else {
                    output.email += dbValue.substring(dbValue.length - part.characters);
                }
            }
        }
    });

    passwordParts.forEach(part => {
        if (part.name == 'Text') {
            output.password += part.value;
        } else {
            const field = part.field;
            const dbValue = user[field];

            if (part.characters > 0 && dbValue) {
                if (part.align == 'left') {
                    output.password += dbValue.substring(0, part.characters);
                } else {
                    output.password += dbValue.substring(dbValue.length - part.characters);
                }
            }
        }
    });

    if (output.email) {
        output.email += `${googleDomain}`;
    }
    return output;
}
</script>

<style scoped>
</style>